import React from 'react'

const Portfolio = () => {
    return(
        <React.Fragment>
            <section className="content-container">
                Portfolio
            </section>
        </React.Fragment>
    )
}

export default Portfolio;